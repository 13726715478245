import { AssetType } from "@iventis/domain-model/model/assetType";
import { Terrain } from "@iventis/domain-model/model/terrain";
import QueryString from "qs";
import { tileToGeoJSON } from "@mapbox/tilebelt";
import booleanOverlap from "@turf/boolean-overlap";
import booleanWithin from "@turf/boolean-within";
import { getSignedUrl, RestrictedCdnSignature } from "@iventis/api-helpers";
import { mapTilerTerrainTileUrlStartsWith, mapTilerTerrainUrl } from "./engine-mapbox-types-and-constants";

const urlsToExcludeCredentials = [
    "https://cdn.iventis.co.uk",
    "https://cdn-restricted.iventis.co.uk",
    "https://cdn.iventis.com",
    "https://cdn-dev.iventis.com",
    "https://cdn.iventis.sa.com",
    "https://cdn-dev.iventis.sa.com",
    "https://api.maptiler.com",
    "https://api.mapbox.com",
    "https://maps.hereapi.com",
    "https://a.tiles.mapbox.com",
    "https://b.tiles.mapbox.com",
    "https://ecn.t0.tiles.virtualearth.net",
    "https://ecn.t1.tiles.virtualearth.net",
    "https://ecn.t2.tiles.virtualearth.net",
    "https://ecn.t3.tiles.virtualearth.net",
    "https://api.nearmap.com/",
    "https://tile.googleapis.com/",
    "https://tiles.basemaps.linz.govt.nz",
    "https://maps.six.nsw.gov.au",
];
const urlsToExcludeAuthorization = [
    "https://cdn.iventis.co.uk",
    "https://cdn-restricted.iventis.co.uk",
    "https://cdn.iventis.com",
    "https://cdn-dev.iventis.com",
    "https://cdn.iventis.sa.com",
    "https://cdn-dev.iventis.sa.com",
    "https://api.maptiler.com",
    "https://api.mapbox.com",
    "https://api.os.uk",
    "https://maps.hereapi.com",
    "https://api.nearmap.com/",
    "https://tile.googleapis.com/",
    "https://tiles.basemaps.linz.govt.nz",
    "https://maps.six.nsw.gov.au",
];
const restrictedCdnUrls = [
    "https://cdn-restricted.iventis.co.uk",
    "https://cdn.iventis.com",
    "https://cdn-dev.iventis.com",
    "https://cdn.iventis.sa.com",
    "https://cdn-dev.iventis.sa.com",
];

export const bustTileCache = (tileUrls: string[]) =>
    tileUrls.map((tileUrl) => {
        const [urlBody, queryString] = tileUrl.split("?");
        const queryStringParsed = QueryString.parse(queryString || "");
        const cacheBustedQueryString = QueryString.stringify({ ...queryStringParsed, bustMe: new Date().getMilliseconds() });
        return `${urlBody}?${cacheBustedQueryString}`;
    });

/** This is the function gets called when Mapbox makes a request */
export const transformRequest = (url, signatures: RestrictedCdnSignature[], authorizationToken = undefined, terrains: Terrain[] = [], projectId?: string) => {
    let requestUrl: string = url;
    let signedUrl: string = url;
    let urlObject: URL;
    let headers = {};
    // If a terrain check if the tile is within the bounds of a custom terrain
    // Terrains within a custom bounds need to be served from us
    if (isMapTilerTerrainUrl(url)) {
        const { x, y, z } = getMapTilerTerrainTileCoords(url);
        if (z != null && x != null && y != null) {
            const tileFeature = tileToGeoJSON([x, y, z]);
            if (tileFeature.type === "Polygon") {
                requestUrl = getCustomTerrainUrl(terrains, tileFeature, signatures, url, x, y, z);
            }
        }
    }

    // No need to add signature for map icons as we handle that separately
    if (
        restrictedCdnUrls.some((restrictedCdnUrl) => requestUrl.toLowerCase().startsWith(restrictedCdnUrl)) &&
        !(requestUrl.includes(AssetType.MapIcon) || requestUrl.includes(AssetType.ProfileImage))
    ) {
        signedUrl = getSignedUrl(requestUrl, signatures);
    }
    if (!requestUrl.startsWith("/")) {
        urlObject = new URL(signedUrl);
        if (urlObject.hostname === "api.os.uk") urlObject.searchParams.append("srs", "3857");
    }
    if (authorizationToken && projectId && !urlsToExcludeAuthorization.some((u) => requestUrl.toLowerCase().startsWith(u))) {
        headers = { authorization: `Bearer ${authorizationToken}`, "project-id": projectId };
    }
    return {
        headers,
        url: urlObject ? urlObject.toString() : signedUrl,
        credentials: (urlsToExcludeCredentials.some((u) => requestUrl.toLowerCase().startsWith(u)) ? "same-origin" : "include") as "same-origin" | "include",
    };
};

const isMapTilerTerrainUrl = (url: string) => url.startsWith(mapTilerTerrainTileUrlStartsWith) && url !== mapTilerTerrainUrl;

const getMapTilerTerrainTileCoords = (url: string): { x: number; z: number; y: number } => {
    const splitUrl = url.split(mapTilerTerrainTileUrlStartsWith);
    const end = splitUrl[1];
    const endSplit = end.split("/");
    const z = Number(endSplit[0]);
    const x = Number(endSplit[1]);
    const y = Number(endSplit[2].split(".")[0]);
    return { x, z, y };
};

const getCustomTerrainUrl = (terrains: Terrain[], tileFeature, signatures, defaultUrl, x, y, z): string => {
    // Determine if tile is within a custom terrain boundary
    let matchingTerrain;
    const hasMatch = terrains.some((terrain: Terrain) => {
        const overlaps = booleanOverlap(terrain.bounds, tileFeature);
        const within = booleanWithin(tileFeature, terrain.bounds);

        if (overlaps || within) {
            matchingTerrain = terrain;
            return true;
        }
        return false;
    });

    if (hasMatch && matchingTerrain) {
        const matchingSignature = signatures.find((s: RestrictedCdnSignature) => s.url.includes(matchingTerrain.id));

        if (matchingSignature?.url) {
            return `${matchingSignature.url}tiles/${z}/${x}/${y}.webp`;
        }
    }

    return defaultUrl;
};
